/* .card {
    border: 1px solid #ddd;
    padding: 10px;
  }
  
  .card-body {
    padding: 10px;
  }
  
  .card-body .row {
    margin-bottom: 8px;
  }
  
  .card-body .row div {
    padding: 5px 0;
  }
  
  .card-body .row div:first-child {
    font-weight: bold;
  }
  

  table.application-status-table{
    border-collapse: collapse;
    border: none;
    padding: 30px !important;
  }
 
  .heaging-theam-color{
    border: none;
  }

  .rounded-top-left {
    border-radius: 6px 0px 0px 0px;
  }
  .rounded-top-right {
    border-radius: 0px 6px 0px 0px;
  }
  .application-status-table tr td{
    border:none
  }
  
  .heaging-theam-color{
    background-color: var(--theme-color) !important;
    color: #fff !important;
  }

 */
 .custom-alert {
  background-color: rgba(127, 52, 135, 0.1); /* Light theme color with reduced opacity */
  color: #7f3487; /* Text color to match theme */
  padding: 20px;
  border-radius: 10px;
  border: 2px solid #7f3487; /* Border color to match theme */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
}

.custom-alert h4 {
  margin-top: 0;
  font-size: 1.5rem;
  color: #7f3487; /* Heading color to match theme */
}

.custom-alert p {
  margin-bottom: 15px;
  font-size: 1rem;
  line-height: 1.5;
}

.custom-alert hr {
  border-top: 1px solid rgba(127, 52, 135, 0.2); /* Light border for the horizontal rule */
}

.custom-btn {
  background-color: rgba(127, 52, 135, 0.1);
  color: #7f3487;
  border: 2px solid #7f3487;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s, border-color 0.3s;
}

.custom-btn:hover {
  background-color: #7f3487;
  color: white;
  border-color: #7f3487;
}
/* alert end */

 .container{
  max-width: 100%;
  margin: 0 auto;
  width: 100%;
  
}
.offer-button:hover{
  color: #fff !important;
}
.my-application-text{
  color: var(--theme-color);
  font-weight: 600;
  font-size: 18px;
}
.status-table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0px 0px 20px rgba(0,0,0,0.1);
}
.status-table th, .status-table td {
  text-align: start;
  vertical-align: middle;
  padding: 15px;
}
.status-table th {
  background-color: #732F7A;
  color: white;
  border: none;
  font-size: 16px;
  font-weight: 500;
}
.status-table td {
  background-color: #fff;
  border: none; /* Remove border from cells */
  font-size: 14px;
  font-weight: 500;
}

.table-container {
  padding-left: 15px; /* Add padding to left */
  padding-right: 15px; /* Add padding to right */
}

.rounded-top-left{
  border-radius: 6px 0px 0px 0px;
}
.rounded-top-right{
  border-radius: 0px 6px 0px 0px;
}
.rounded-bottom-left{
  border-radius: 0px 0px 0px 6px;
}
.rounded-bottom-right{
  border-radius: 0px 6px 6px 0px;
}
.status-table td {
  background-color: #fff;
}

.status-table th, .status-table td {

 
  border: none !important;
}

.status-table a {
  color: #682f92;
  font-weight: bold;
  text-decoration: none;
}
.status-table a:hover {
  text-decoration: underline;
}
.btn-request {
  background-color: #732F7A !important;
  color: white;
  float: right;
  margin-bottom: 20px;
}
.border-bottom-td{
  padding: 0px 30px !important;
}
.add-border-bottom{
  border-bottom: 1px solid #dee2e6;
}

.status-link {
  cursor: pointer;
}
.status-link:hover {
  cursor: pointer;
  color: var(--theme-color);
  text-decoration: underline;
  font-weight: 500;
}

.status-table th:first-child, 
.status-table td:first-child {
  width: auto; /* Auto width based on content */
  padding-left: 50px; /* Add left padding if needed */
}

.status-table th, 
.status-table td {
  padding: 15px; /* General padding */
}
.card-body{
  padding: 0px 12px;
  box-shadow: 0px 0px 20px rgba(0,0,0,0.08);
}
.Read-more{
  background-color: #e5d6e7;
  border-radius: 4px;
  padding: 5px 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  box-shadow: 0px 0px 15px rgba(0,0,0,0.1);
}
.add-border-bottom{
  border-bottom: 1px solid #e5d6e7;
  padding: 14px;
}

.card-body .row div:nth-child(1){
  background-color: var(--theme-color);
  color: white;
}

.add-padding{
  padding: 14px;
}