.close-btn-container {
  position: absolute;
  top: 0;
  right: 0;
  margin: 8px 8px;
  cursor: pointer;
  /* padding: 10px; */
  background-color: #852e8459;
  line-height: 0px;
  padding: 5px;
  border-radius: 5px;

}

.close-btn-rotate {
  width: 20px;
  transition: transform .25s, opacity .25s;
}

.close-btn-container:hover .close-btn-rotate {
  opacity: 1;
  transform: rotate(90deg);
}

.profilemodal-container.modal-dialog {
  width: 100% !important;
  padding: 24px 32px;
  max-width: 712px;
  margin: auto !important;
}

.personalinfo-container {
  padding: 24px 32px;
  border-radius: 4px;
  gap: 24px;
}

.profile-image-content {
  gap: 20px;
  padding: 0px 20px;
}

.profile-image {
  background: url(../../../assets/Background.png);
  width: 150px;
  height: 150px;
  background-repeat: no-repeat;
  background-size: 150px;
  border-radius: 100px;
}

.profile-image img {
  width: 150px;
  height: 150px;
  border-radius: 100px;
}

.profile-info-tab {
  gap: 24px;
  width: 100%;
}

.profile-image-content span {
  color: #4B465C;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
}

.detail-tab-container {
  gap: 24px;
  display: flex;
}

.personal-detail-tab {
  gap: 16px;
  cursor: pointer;
}

.personal-detail-tab.active .details-image {
  background: var(--theme-color);
}

.personal-detail-tab.active .details-image img {
  filter: brightness(3);
}

.personal-detail-tab.active .details-image path,
.personal-detail-tab.active .details-image circle {
  stroke: #FFFFFF;
}

.details-image {
  padding: 8px;
  box-shadow: 0px 2px 4px 0px rgba(165, 163, 174, 0.30);
  border-radius: 6px;
  width: 40px;
  height: 40px;
  background-color: rgba(75, 70, 92, 0.08);
}

.details-image img {
  width: 24px;
  height: 24px;
}

.personal-detail-tab span {
  color: #4B465C;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
}

.profile-divider {
  height: 1px;
  background: var(--theme-color);
}

.details-title {
  color: #4B465C;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
}

.check-box-input {
  width: 20px !important;
  height: 20px !important;
  min-height: 20px !important;
}


.form-check-input:checked {
  background-color: var(--theme-color) !important;
  border-color: var(--theme-color) !important;
}

.form-check-input:focus {
  box-shadow: none !important;
}


@media (max-width:991px) {
  .profilemodal-container.modal-dialog .personalinfo-container {
    margin-top: 0px !important;
  }

  .detail-tab-container {
    gap: 8px;
    margin-top: 10px;
  }

  .personal-detail-tab {
    gap: 13px;
  }

  .personal-detail-tab span {
    color: #4B465C;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
  }
}

@media (max-width:767px) {
  .profile-info-tab {
    gap: 24px;
  }

  .personalinfo-container {
    padding: 24px;
  }
}

@media (max-width:425px) {
  .profile-info-tab {
    flex-direction: column !important;
  }

  .detail-button-container {
    flex-direction: column;
    gap: 16px;
    justify-content: flex-end !important;
    align-items: end;
  }
}

/* .personalinfo-container {
  animation: fadeIn 0.5s forwards;
} */

/* .personal-detail-form  {
  transition: all 0.5s ease-in;
} */

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}