 /*single card css*/

 .max-width-single-card{
    max-width: 1392px;
    margin: 0 auto;
    background-color: #fff;
    padding: 30px;
    border-radius: 6px;
   }
   .single-card-h3{
    color: var(--theme-color);
    font-size: 18px;
    font-weight: 600;
    padding: 7px;
    font-size: 24px;
   }
  
   .outline-secondary-button{
    border:1px solid var(--theme-color);
    background-color: #fff;
    color: var(--theme-color);
    padding: 10px 36px;
    border-radius: 4px;
   }
  
   .new-secondary-button{
    background-color: var(--theme-color);
    color: #fff;
    padding: 10px 36px;
    border-radius: 4px;
    border: none;
   }
  
   .add-liniar-background{
    background-image: linear-gradient(
      0deg,
      hsl(0, 0%, 100%) 0%,
      hsla(0, 0%, 100%, 0.953) 50%,
      hsla(0, 0%, 100%, 0.426) 100%
    );
    
  height:350px;
   }
  
  