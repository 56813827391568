.accordion {
  --bs-accordion-bg: var(--background-color);
  border: 0px;
}

.accordion__item {
  border: 0px !important;
  margin-bottom: 10px;
}

.accordion__item .accordion__panel .accordion .accordion__item .accordion__panel table {
  width: 100% !important;
}

.accordion__item .accordion__panel .accordion .accordion__item .accordion__panel table tr td {
  width: 33.33%;
}

.accordion__item .accordion__panel .accordion .accordion__item .accordion__panel table tr td div {
  display: flex;
  justify-content: center;

}

.accordion__button {
  display: flex;
  align-items: center;
  border: 0px;
  border-radius: 6px;
  /* color: var(--theme-color); */
  /* font-size: 14px; */
  font-weight: 700;
  /* line-height: 60px; */
  flex-direction: row-reverse;
  justify-content: space-between;
  /* background-color: var(--background-color); */
}

.accordion__panel {
  background-color: white;
  border-top: 2px solid var(--background-color);
  overflow-x: auto;
}

.selectData_button {
  margin-right: 12px;
}

.scholarship-table-width th,
td {
  width: 33.33%
}

.scholarship-table-text-center {
  text-align: center;
}

@media (max-width:576px) {

  .accordion__panel .table thead,
  .modal-dialog .table thead {
    display: none;
  }

  .accordion__panel .table,
  .modal-dialog .table {
    width: 100% !important;
  }

  .accordion__panel .table tbody tr,
  .modal-dialog .table tbody tr {
    display: flex;
    flex-direction: column;
  }

  .accordion__panel .table tbody tr td:nth-child(1)::before {
    content: "Status :";
    font-weight: bold;
  }

  .accordion__panel .table tbody tr td:nth-child(2)::before {
    content: "Date : ";
    font-weight: bold;
  }

  .accordion__panel .table tbody tr td:nth-child(3)::before {
    content: "Comments : ";
    font-weight: bold;
  }

  .accordion__panel .table.loan-table tbody tr td::before,
  .modal-dialog .table tbody tr td::before {
    content: attr(aria-label) !important;
    font-weight: bold;
  }

  .drfatsstatus .accordion__panel .table tbody tr td::before {
    content: attr(aria-label) !important;
    font-weight: bold;
  }

  .accordion__panel .table.drafts-loan tr,
  .modal-dialog .table tr {
    border-bottom: 1px solid #2c2c2c !important;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .accordion__item .accordion__panel .accordion .accordion__item .accordion__panel table tr td div {

    justify-content: start !important;
  }

  .scholarship-table-width th,
  td {
    width: 100% !important;
  }

  .scholarship-table-text-center {
    text-align: start;
  }

}