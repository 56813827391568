.notification {
  position: absolute;
  top: 2rem;
  right: 0;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 4px 16px 0px rgba(165, 163, 174, 0.45);
  width: 240px;
}

.notification-inner {
  display: grid;
  gap: 4px;
  /* padding-top: 8px; */
}

.notification-title {
  font-size: 13px;
  font-weight: 700;
  line-height: 14px;
  color: var(--theme-color3);
}

.notificationName {
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  color: var(--theme-color2);
  text-transform: capitalize;
}

.approved-time {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  color: var(--theme-color2);
}
