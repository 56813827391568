 /*multiple card*/

 /* .max-width-multiple-card{
    max-width: 1392px;
    margin: 0 auto;
    padding: 30px;
    border-radius: 6px;
   } */
  .inner-card{
    background-color: #fff;
    max-width: 430px;
    padding: 20px;
    border-radius: 6px;
  }
   .multiple-card-h4{
    color: #1D1D1B;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 15px;
   }
   .multiple-card-p{
    color: #5d586c;
    font-size: 14px;
   }
   .inner-card-button{
    padding: 10px 20px;
    background-color: var(--theme-color);
    color: #fff;
    border: none;
    border-radius: 6px;
    font-size: 15px;
    font-weight: 500;
   }

   .back-button-design{
    background-color: transparent;
    border-radius: 33px;
    border: 1px solid currentColor;
    height: 40px;
   }