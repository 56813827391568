.secondary-btn {
    padding: 0px 20px;
    border-radius: 6px;
    border: 1px solid var(--theme-color);
    background-color: transparent;
    height: 40px;
    font-size: 15px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.43px;
    color: var(--theme-color);
}

@media (max-width: 1299px) {
  }
  
  @media (max-width: 991px) {
  }
  
  @media (min-width: 768px) {
  }
  
  @media (max-width: 767px) {
    
  }
  
  @media (max-width: 575px) {
    .secondary-btn{
        border-radius: 4px;
        height: 32px;
        font-size: 10px;
       }
  }
  
  @media (max-width: 375px) {
   .secondary-btn{
    border-radius: 4px;
    height: 32px;
    font-size: 10px;
   }
  }