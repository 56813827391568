   .forminput-common:focus {
  box-shadow: none;
  outline: none;
  border-color: #e0e0e0;
}

.forminput-common {
  border-radius: 6px;
  border: 1px solid #e0e0e0;
  background: #fff;
  padding: 8px 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.37);
}

select.forminput-common {
  appearance: none;
  background: url(../../../assets/chevron-down.svg) no-repeat right white;
  background-size: 2rem 1rem, 1rem 1rem;
}

.form-input-common::placeholder {
  color: rgba(0, 0, 0, 0.37) !important;
}

.forminput-label {
  color: rgba(0, 0, 0, 0.6);      
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.forminput {
  gap: 4px;
}

/* REACT date piker */

.react-datepicker__input-container input {
  width: 100%;
  border-radius: 6px;
  border: 1px solid #e0e0e0;
  background: #fff;
  padding: 8px 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.37);
}

.react-datepicker__input-container .react-datepicker__.view-calendar-ico{
    display: flex;
    align-items: center;
}

.react-datepicker__input-container input:focus-visible {
  box-shadow: none;
  outline: none;
  border-color: var(--theme-color) !important;
}

.react-datepicker__view-calendar-icon input {
  color: black;
 
}

.form-input-common .react-datepicker__input-container{
  display: flex;
  align-items: center;
}
.form-input-common .react-datepicker__input-container input{
  font-size: 14px;
}