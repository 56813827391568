.footer {
  background-color: var(--theme-color3) !important;
  /* padding: 48px 0px; */
  /* padding: 32px 80px; */
  margin: 0px -24px 0px -24px;
}

.footer-input-container {
  /* border: 2px solid white; */
  /* border-radius: 50px;*/
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 410px;
  background: transparent;
  height: 60px;
  flex-shrink: 0;
  border-radius: 70px;
  border: 2px solid #fff;
}

.footer-input {
  color: white;
  background: transparent;
  border: 0px;
  min-width: 0px;
  padding-left: 1.5rem;
  width: 88%;
}

.footer-input::placeholder {
  color: #fffefe;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.footer-input:focus-visible {
  outline: none;
}

.footer-container {
  max-width: 1000px;
  margin: 0px auto;
}

.footer-button {
  color: white;
  border: none;
  padding: 5px;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  margin-right: 4px;
}

.footer-table {
  color: white;
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
}

.footer-row {
  display: grid;
  grid-template-columns: 1.5fr 2fr 1.5fr;
}

.addresscard-main-container {
  height: 240px;
}

.addresscard-main {
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.addresscard-middle-content {
  align-items: center;
  justify-content: center;
  border-right: 1px solid var(--theme-color3);
  border-left: 1px solid var(--theme-color3);
}

.addresscard-image {
  width: 85px;
  height: 85px;
  background: linear-gradient(0deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)),
    #7f3487;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addresscard-title {
  font-family: var(--theme-font-family-hading);
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: var(--theme-color3);
}

.addresscard-description,
.addresscard-description a {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  align-items: center;
  color: #7c7c87;
  text-decoration: none;
}

.links-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: var(--theme-color3);
}

.links-liust {
  list-style: none;
  padding-left: 0rem !important;
}

.link-card {
  gap: 1.5rem;
}

.addresscard-description span {
  display: block;
}

.text-size {
  margin-bottom: 35px;
}

.footer-company-logo {
  margin-bottom: 15px;
}

.cool-link {
  position: relative;
}

.cool-link::before {
  transition: 300ms;

  height: 2px;
  content: "";
  position: absolute;
  background-color: #ffffff;
}

.cool-link-ltr::before {
  width: 0%;
  bottom: 0px;
}

.cool-link-ltr:hover::before {
  width: 100%;
}

.footer-text {
  text-align: center;
  font-size: 18px;
  color: white !important;
  text-decoration: none;
}

.active-tab{
  color: white !important;
  font-weight: 800;
  /* border-bottom: 2px solid white !important; */
}

@media (max-width: 991px) {
  .footer {
    margin-top: 32px;
    padding: 44px;
  }

  .footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
}



@media (max-width:800px) {
  .text-size {
    font-size: 15px;
  }
}

@media (max-width: 768px) {

  /* .footer-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  } */
  .footer-row {
    display: flex !important;

  }
  .footer-text {
    text-align: center;
    font-size: 18px;
  }
}

@media (max-width: 667px) {
  .footer-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    gap: 1rem;
    row-gap: 2rem;
  }

  .link-card {
    gap: 0.5rem;
    padding: 0rem 0.5rem !important;
  }

  .footer-links {
    display: flex !important;
    justify-content: space-between;
  }

  .addresscard-middle-content {
    border-right: 0px solid var(--theme-color3);
    border-left: 0px solid var(--theme-color3);
  }

  .addresscard-main {
    justify-content: flex-start;
    height: auto;
  }

  .addresscard-main-container {
    height: auto;
  }

  .addresscard-first-content {
    grid-column: 1/3;
  }

  .addresscard-image {
    width: 35px;
    height: 35px;
    border-radius: 2px;
  }

  .addresscard-description span {
    display: inline;
  }

  .addresscard-description {
    font-size: 12px;
    line-height: 17px;
  }

  .addresscard-title {
    font-family: var(--theme-font-family-hading);
    font-size: 14px;
    line-height: 17px;
  }

  .footer-row {
    gap: 1.5rem;
  }

  .footer-links {
    padding-top: 2rem !important;
  }

  .addresscard-description a {
    font-size: 13px;
  }

  .links-title {
    font-size: 16px;
  }
}

@media (max-width: 575px) {
  .footer {
    padding: 24px;
  }
}

@media (max-width: 500px) {
  .footer-row.footer-links {
    display: grid !important;
    justify-content: space-between;
    grid-template-columns: repeat(2, 1fr);
  }

  .link-card:last-child {
    grid-column: 1/3;
  }

  .addresscard-main {
    padding: 0rem !important;
  }
  .footer-text {
    text-align: center;
    font-size: 18px;
  }
}

@media (max-width: 330px) {
  .footer-row {
    grid-template-columns: 1fr;
  }

  .addresscard-middle-content {
    grid-column: 2/1;
  }

  .addresscard-third-content {
    grid-column: 3/1;
  }
}

@media (max-width:340px) {
  .footer-input {
    padding-left: .7rem;
  }
  .footer-text {
    text-align: center;
    font-size: 14px;
  }

}

@media (max-width:315px) {
  .footer-input::placeholder {
    width: 120px;
  }

  .footer-text {
    text-align: center;
    font-size: 14px;
  }

}