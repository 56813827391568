.signupOtp-text {
    color: var(--theme-color2);
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    font-family: var(--theme-font-family-mulish);
    font-style: normal;
}

.mobilenumber-input,
.otp-input::placeholder,
.mobilenumber-input::placeholder,
.mobilenumber-label {
    color: var(--theme-color2);
    font-size: 13px;
    font-weight: 400;
    line-height: normal;
}

.mobilenumber-input::placeholder,
.otp-input::placeholder {
    color: rgb(75 70 92 / 40%) !important;
}

.mobilenumber-input {
    height: 40px;
}

.otp-input {
    height: 40px;
    width: 35px !important;
    padding: 0px !important;
    text-align: center;
}

.mobilenumber-input:focus,
.otp-input:focus {
    box-shadow: none !important;
    outline: none !important;
    border-color: #dee2e6 !important;
}

.verify-btn {
    padding: 0px 20px;
    border-radius: 6px;
    border: 1px solid rgb(38 50 56 / 60%);
    color: rgb(38 50 56 / 60%);
    background-color: #FFFFFF;
    pointer-events: none;
}

.verified {
    padding: 0px 20px;
}

.resendotp-text span {
    color: rgb(38 50 56 / 80%);
    font-size: 15px;
    font-weight: 700;
    line-height: 24px;
}

.resendotp-text-mute span {
    color: rgb(38 50 56 / 40%);
    font-size: 15px;
    font-weight: 700;
    line-height: 24px;
}

@media (max-width:575px) {
    .modal-dialog.cart-model-content {
        margin: auto !important;
    }
}

@media (max-width:375px) {
    .modal-dialog.cart-model-content {
        width: 100% !important;
    }
}