.forminput-common:focus {
  box-shadow: none;
  outline: none;
  border-color: #e0e0e0;
}

.forminput-common {
  border-radius: 6px;
  border: 1px solid #e0e0e0;
  background: #fff;
  padding: 8px 10px;
  font-size: 16px;

  font-weight: 400;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.37);
}


.selected-option:hover {
  background-color: var(--grey-grey-lighten-2);
}

select.form-input-common {
  padding: 1px;
  height: 30px;
  padding-left: 8px;
  color: var(--opacity-text-disabled, rgba(0, 0, 0, 0.37));
  appearance: none;
  background: url(../../../assets/chevron-down.svg) no-repeat right white;
  background-size: 2rem 1rem, 1rem 1rem;
}
.form-input-label{
  margin-bottom: .2rem;
}

.form-input-common {
  width: 100%;
  min-height: 38px;
  /* min-height: 50px; */
  /* margin-bottom: 18px; */
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 6px;
  font-size: 14px;
  /* color: var(--opacity-text-disabled, rgba(0, 0, 0, 0.37)); */
  background: #fff;
  border-radius: 6px;
  border: 1px solid var(--grey-grey-lighten-2, #e0e0e0);
  background: #fff;
  outline: none;
}

/* remove number input arrow */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-input-common[type="number"] {
  -moz-appearance: textfield;
}

.form-label {
  color: var(--opacity-text-secondary, rgba(0, 0, 0, 0.6));
  font-feature-settings: "clig" off, "liga" off;
  font-family: var(--theme-font-family-mulish);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.form-input-common::placeholder {
  color: var(--opacity-text-disabled, rgba(0, 0, 0, 0.37));
  font-feature-settings: "clig" off, "liga" off;
  font-family: var(--theme-font-family-mulish);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.form-input-common:focus-visible {
  border-color: var(--theme-color);
}

.form-input-label {
  color: #263238;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
}

/* .forminput {
    gap: 4px;
} */

.error {
  font-size: 10px;
  padding: 2px 5px;
  margin: 0;
  color: red;
}
.custom-creatable-select {
  min-height: 20px;
}

@media (max-width: 430px) {
  .form-input-common {
    min-height: 40px;
  }
}
