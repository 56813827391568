 .dropdown-menu:active{
  background-color: aliceblue;
}

.dropdown-toggle::after {
  display: none !important;
}

.nav-dropdown {
  position: absolute;
  top: 4.5rem;
  right: 0;
  margin: 0 1.25rem;
  /* padding: 12px; */
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 4px 16px 0px rgba(165, 163, 174, 0.45);
  z-index: 1;
}

.customermenu-inner {
  display: grid;
  gap: 12px;
}

.menu-title {
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  color: var(--theme-color3);
  text-decoration: none;
}
