body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.w-fit{
  width: fit-content !important;
}

.min-h-50{
  min-height: 50px !important;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.personal-detail-form .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__day--keyboard-selected{
  background-color: #852e8459 !important;
}
.personal-detail-form .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__day--selected{
  background-color: #7f3487 !important;
}