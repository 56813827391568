.loader {
    border: 6px solid #f3f3f3;
    border-radius: 50%;
    border-top: 6px solid var(--theme-color);
    width: 44px;
    height: 44px;
    animation: spin 3s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}