.loan-card-inner{
    height: auto;
   max-width: 1400px;
   margin: 0 auto;
   background-color: #fff;
   display: grid;
   grid-template-columns: auto 1fr auto;
   border-radius: 5px;
   overflow: hidden;
   margin-bottom: 40px;
}

.bank-logo .bank-logo-img{
    width: 180px;
}
.bank-logo{
    margin-bottom: 30px;
}
.card-left-side-content{
    padding: 20px;
}
.card-right-side-content{
    padding: 20px 20px 0px 20px;
}
.card-center-side-content{
 
   border-left: 2px solid #7F3487;
   border-right: 2px solid #7F3487;
}

.bank-name{
    background-color: #e5d5e5;
    padding: 5px;
    text-align: center;
    border-radius: 5px;
    border-left: 6px solid #7F3487;
}
.bank-name p{
    margin: 0;
    font-weight: 600;
    color: #000;
}

.card-center-side-content{
    padding: 20px 25px;
}

.bank-name-italic-style{
    color: #7F3487;
    font-weight: 800 !important;
    font-style: italic;
    font-family: mulish;
    font-size: 18px;
    margin-bottom: 15px;
}
.claim-bonus{
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    word-break: break-all;
}
.card-right-side-content{
  
}
.bank-loop-logo .bank-loop-img{
    width: 240px;
}
.loan-price-btn{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #fff;
    font-weight: 600;
    background-color: #7F3487;
    padding: 10px 50px;
    border: none;
    border-radius: 6px;
}
.Loan-price{
   margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.coverupto-text{
    font-weight: 600;
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 8px;
}
.see-details-btn{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #fff;
    font-weight: 600;
    background-color: #7F3487;
    padding: 10px 60px;
    border: none;
    border-radius: 6px 6px 0px 0px;
}
.block-none-img{
    display: none;
}
@media (max-width:991px) {
    .claim-bonus{
        display: flex;
        justify-content: space-between;
        font-weight: 600;
        gap: 15px;
    }
    .claim-bonus{
        font-size: 14px;
    }
    .bank-logo .bank-logo-img{
        width: 160px;
    }
    .bank-loop-logo .bank-loop-img {
        width: 200px;
    }
    .loan-price-btn {
        padding: 6px 50px;

    }
    .see-details-btn {
        padding: 6px 60px;
    }
}

@media (max-width:945px) {
    /* .bank-logo .bank-logo-img{
        width: 180px;
    }
    .bank-loop-logo .bank-loop-img {
        width: 220px;
    } */
}
@media(max-width:850px){
    .claim-bonus {
        font-size: 13px;
    } 
    .bank-name p{
        line-height: 17px;
    }
}

@media (max-width:850px) {
    .bank-logo .bank-logo-img{
        width: 140px;
    }
    .bank-loop-logo .bank-loop-img {
        width: 180px;
    } 
}

/* @media(max-width:768px){
    .loan-card-inner{
        height: auto;
       max-width: 1400px;
       margin: 0 auto;
       background-color: #fff;
       display: grid;
       grid-template-columns: 1fr ;
       border-radius: 5px;
       overflow: hidden;
       margin-bottom: 40px;
    }
    .card-right-side-content{
        grid-column: 1 / -1;
     display: flex;  
     justify-content: space-between;
     border-top: 2px solid #7F3487;
    }
    .card-center-side-content{
        border-right: unset;
    }
    .block-none-img{
        display: block;
    }
    .bank-loop-logo{
        display: flex;
        overflow: hidden;
        gap: 20px;
    }
    .loan-price-btn{
        width: 183.2px;
        height: 56px;
        padding: 0px;
    }
} */



@media(max-width:768px){
    .claim-bonus{
        font-size: 14px;
    }
}
@media (max-width:768px) {

        .loan-card-inner {
            height: auto;
            max-width: 1400px;
            margin: 0 auto;
            background-color: #fff;
            display: grid;
            grid-template-columns: auto;
            border-radius: 5px;
            overflow: hidden;
            margin-bottom: 40px;
        
    }
    .block-none-img{
        display: none;
    }
    .bank-loop-img{
        display: none;
    }
    .card-center-side-content{
        border-left: none;
        border-right: none;
        border-top: 2px solid #7F3487;
    }
    .bank-logo{
        text-align: center;
    }
    .card-right-side-content{
     justify-content: start; 
     padding: 10px 16px 0px 16px;
    }
    .Loan-price{
        flex-direction: row;
        align-items: start;
        justify-content: space-between;
        width: 100%;
    }
    .bank-name p {
        line-height: 20px;
    }
    .bank-logo .bank-logo-img {
        width: 170px;
    }
    .see-details-btn{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        color: #fff;
        font-weight: 600;
        background-color: #7F3487;
        padding: 18px 60px;
        border: none;
        border-radius: 6px 6px 6px 6px;
    }
    .card-center-side-content {
        padding: 20px 16px;
    }
    .card-left-side-content {
        padding: 20px 16px;
    }
    .bank-logo {
        margin-bottom: 23px;
    }
    .bank-name p{
        font-size: 15px;
    }
        .loan-price-btn {
        width: 183.2px;
        height: 48px;
        padding: 0px;
    }
    .see-details-btn{
       padding: 15px 60px;
    }
    .card-right-side-content{
     border-top: 2px solid #7F3487;
    
    }
   
}

@media (max-width:450px) {
    .Loan-price{
        flex-direction: column;
    }
    .loan-price-btn{
        width: 100%;
    }
    .width-full-div{
        width: 100%;
    }
    .see-details-btn{
        width: 100%;
        padding: 10px 60px;
        margin-bottom: 15px;
    }
}

@media(max-width:400px){
    .claim-bonus{
        flex-direction: column;
        justify-content: start;
        gap: 6px;
    }
    .claim-bonus p{
        margin: 0;
    }
    .fixed-color-mobile{
        margin-bottom: 18px !important;
       color: #858585;

    }
    .claim-bonus{
        font-size: 15px;
    }
  
}