.previous-btn {
    background-color: var(--theme-color);
    width: fit-content;
    border: none;
    border-radius: 6px;
    box-shadow: 0px 2px 4px rgba(165, 163, 174, 0.3);
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.43px;
    color: #263238;
    padding: 0rem 1.5rem;
    height: 48px;
    gap: 8px;
}

@media (max-width:425px) {
    .previous-btn {
        font-size: 13px;
        padding: 0rem 1rem;
        height: 40px;
    }
}