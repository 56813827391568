.accordian_data table.crowdfunding-table th,td{
    width:calc(100% / 5) !important;
  }

@media (max-width: 1299px) {
    .body {
      padding: 0rem 20px;
    }
    .accordian_data{
      overflow: auto;
    }
    .accordian_data table.crowdfunding-table{
      width:1300px !important;
      overflow: auto;
        
    }
    .accordian_data::-webkit-scrollbar {
      display: none;
    }
    
    /* Hide scrollbar for IE, Edge and Firefox */
    .accordian_data {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
  }
  
/*myLoan page table css*/

  
  
  @media (max-width: 768px) {
    .accordian_data .crowdfunding-table thead tbody tr {
        display: flex !important;
        flex-direction: column !important;
    }
  }
  

@media(max-width:576px){
    .accordian_data .crowdfunding-table thead{
      display: none;
    }
    .accordian_data table.crowdfunding-table th,td{
      width:100% !important;
      display: flex;
      gap: 10px;
    }
    .accordian_data table.crowdfunding-table{
      width:100% !important;
      overflow: none;
        
    }
    .accordian_data table.crowdfunding-table tbody tr td:nth-child(1)::before{
      content: "Date : ";
      font-weight: bold;
      word-break: break-all;
    }
    .accordian_data table.crowdfunding-table tbody tr td:nth-child(2)::before{
      content: "Received Amount   : ";
      font-weight: bold;
      word-break: break-all;
    }
    .accordian_data table.crowdfunding-table tbody tr td:nth-child(3)::before{
      content: "Commission : ";
      font-weight: bold;
      word-break: break-all;
    }
    .accordian_data table.crowdfunding-table tbody tr td:nth-child(4)::before{
      content: "Processing Fees : ";
      font-weight: bold;
      word-break: break-all;
    }
    .accordian_data table.crowdfunding-table tbody tr td:nth-child(5)::before{
      content: "Status : ";
      font-weight: bold;
      word-break: break-all;
    }
   
  }