.applynow-btn {
    background-color: var(--theme-color);
    width: 120px;
    /* height: 39px !important; */
    border: none;
    border-radius: 6px;
    box-shadow: 0px 2px 4px rgba(165, 163, 174, 0.3);
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: 0.43px;
    color: #FFFFFF;
    /* width: 178px; */
 
    padding: 13px 26px;
    height: 48px;
}
.applynow-btn[disabled=disabled],.applynow-btn:disabled {
    background-color: var(--theme-color2);
    /* width: fit-content; */
    /* width: 120px; */
    height: 48px !important;    
    border: none;
    border-radius: 6px;
    box-shadow: 0px 2px 4px rgba(165, 163, 174, 0.3);
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.43px;
    color: #FFFFFF;
    /* width: 178px; */
    padding: 0rem 1.5rem;
    height: 48px;
}

.btn-loader {
  width: 16px;
  height: 16px;
  border: 3px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  left:4px;
  top: 10px;

}

@media (max-width:425px) {
    .applynow-btn {
        font-size: 13px;
        padding: 0rem 1rem;
        height: 40px;
    }
}


@keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 