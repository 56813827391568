

.logo-outer {
    background-color: #fff;
   height: 140px;
    border-radius: 12px;
    text-align: center;
    transition: border-bottom-width 0.5s ease-in-out, border-bottom-color 0.3s ease-in-out;
    border-bottom: 3px solid transparent; /* Start with a thinner border */
}

.logo-outer:hover {
    border-bottom-width: 3px; /* Increase the width on hover */
    border-bottom-color: var(--theme-color); /* Ensure the color transition is smooth */
}
.third-party-company-logo{
    width: 70%;
}