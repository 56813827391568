/* .myscholarship-main{
    min-height: calc(100vh - 432px);
} */


/* h1 {
  text-align: center;
  margin: 2rem 0 4rem 0;
}

.accordion {
  max-width: 600px;
  margin: 2rem auto;
}

.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  background-color: #21aeca;
}

.accordion-title:hover {
  background-color: #3ab4cc;
}

.accordion-title,
.accordion-content {
  padding: 1rem;
}

.accordion-content {
  background-color: #39b9d2;
}

@media screen and (max-width: 700px) {
  body {
    font-size: 18px;
  }

  .accordion {
    width: 90%;
  }
} */

@media (min-width:1921px) {
    .main .myscholarship-main{
        max-width: 1920px;
        margin: auto;
    }
}