/* LineLoader.css */
.line-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
  }
  
  .line {
    width: 100px; /* Adjust width based on your requirements */
    height: 4px; /* Adjust thickness based on your requirements */
    background: #7f3487; /* Theme color or any color you prefer */
    position: relative;
    animation: lineAnimation 1s infinite;
  }
  
  @keyframes lineAnimation {
    0% {
      transform: scaleX(0);
      opacity: 0;
    }
    50% {
      transform: scaleX(1);
      opacity: 1;
    }
    100% {
      transform: scaleX(0);
      opacity: 0;
    }
  }
  