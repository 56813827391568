* {
  --theme-color: #7f3487;
  --theme-color2: #5d596c;
  --theme-color3: #263238;
  --theme-color4: #ea5455;
  --theme-color5: #7f3487;
  --theme-color6: #28c76f;
  --theme-color7: #ea545560;
  --theme-color8: #102d4f;
  --theme-color9: rgb(52, 52, 52);
  /* --theme-font-family: 'Rubik Broken Fax'; */
  --theme-font-family: "Open Sans";
  --theme-font-family-mulish: "Mulish", sans-serif;
  --theme-font-family-hading: "Mulish", sans-serif;
  /* --theme-font-family-mulish: "Mulish", sans-serif;
  --theme-font-family-hading: "Mulish", sans-serif; */
  /* --theme-font-family-hading: "Poppins"; */
  --theme-font-style: normal;
  --background-color: #eeeeee;
  font-family: var(--theme-font-family);
  font-style: var(--theme-font-style);
}

* {
  scroll-behavior: smooth;
}

.main {
  background-color: var(--background-color);
  max-width: 1920px;
  margin: auto;
  padding: 0rem 24px;
  padding-top: 16px;
  overflow: hidden;
  min-height: 100vh;
}

.body {
  max-width: 1440px;
  margin: auto;
  padding: 0rem 36px;
  /* min-height: 22rem;   */
  
}

.disable-div {
  opacity: 1 !important;
  /* width: fit-content; */
  /* border: 1px solid; */
  /* background: white; */
  border-radius: 6px;
  padding: 16px;
  cursor: not-allowed;
  font-family: var(--theme-font-family-mulish) !important;
}

.applyfor-main {
  padding: 66px 50px;
}

.hading-fount-family {
  font-family: var(--theme-font-family-hading) !important;
}

.font-family-mulish {
  font-family: var(--theme-font-family-mulish) !important;
}
p{
  font-family: var(--theme-font-family-mulish) !important;
}
li{
  font-family: var(--theme-font-family-mulish) !important;
}
div{
  font-family: var(--theme-font-family-mulish) !important;
}
h1{
  font-family: var(--theme-font-family-mulish) !important;
}
h2{
  font-family: var(--theme-font-family-mulish) !important;
}
h3{
  font-family: var(--theme-font-family-mulish) !important;
}
h4{
  font-family: var(--theme-font-family-mulish) !important;
}

.fc-theme {
  color: var(--theme-color) !important;
}

.Toastify__toast--info .Toastify__progress-bar--info {
  background: var(--theme-color) !important;
}

.Toastify__toast--info .Toastify__close-button {
  color: var(--theme-color) !important;
}

.Toastify__toast--info .Toastify__toast-body .Toastify__toast-icon svg {
  fill: var(--theme-color);
}

.Toastify__toast--success .Toastify__progress-bar--success {
  background: var(--theme-color) !important;
}

.Toastify__toast--success .Toastify__close-button {
  color: var(--theme-color) !important;
}

.Toastify__toast--success .Toastify__toast-body .Toastify__toast-icon svg {
  fill: var(--theme-color);
}

/*Error show time chage toast color*/
.Toastify__toast--error .Toastify__toast-body .Toastify__toast-icon svg {
  fill: #ff0000 !important;
}

.Toastify__toast--error .Toastify__progress-bar {
  background: #ff0000 !important;
}

.fixed_min-height {
  min-height: calc(100vh - 253px);
  /* min-height: 100vh; */
}

.accordion__button:before {
  min-width: 10px;
  min-height: 10px;
}

.accordion__button {
  gap: 15px;
}

.infoicon-fixedsize {
  max-height: 24px;
  min-width: 24px;
  width: 24px;
}

.duhueh {
  overflow: hidden;
  height: 0;
  transition: height 0.5s ease;
}

.xyz {
  /* animation: demo .5s ease-in; */
  height: auto;
}

.accordian_main_title {
  padding: 18px;
  background-color: #f4f4f4;
  border-radius: 6px;
  color: var(--theme-color);
  font-weight: 700;
  cursor: pointer;
}

.accordian_data {
  padding: 20px;
  background-color: #fff;
}

.accordian_data_header {
  padding: 0px;
  background-color: #fff;
  border-radius: 10px 10px 5px 5px;
  margin: 0px 8px;
}

.hidden {
  display: none;
}

.rotat-arrow {
  transform: rotate(90deg);
}



/*myLoan page table css*/
.accordian_data table.loan-table th,
td {
  width: calc(100% / 6) !important;
}

.header-option-main-title {
  color: #5d596c;
  padding: 18px 15px 18px 30px;
}

.header-option-main-title img {
  height: 12px;
  width: 12px;
}


@media (max-width: 768px) {
  .accordian_data .loan-table thead tbody tr {
    display: flex !important;
    flex-direction: column !important;
  }
}

@media (max-width: 1299px) {
  .body {
    padding: 0rem 20px;
  }

  .accordian_data {
    overflow: auto;
  }

  .accordian_data table.loan-table {
    width: 1300px !important;
    overflow: auto;

  }

  .accordian_data::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .accordian_data {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}

@media (max-width:991px) {
  .accordian_data_header .dropdown-item {
    padding: 11px 10px 10px 43px;
  }
}

@media(max-width:767px) {
  .main {
    padding: 10px 16px 0px 16px;
  }
}

@media (max-width: 575px) {
  .body {
    padding: 0rem 0px !important;
  }

  .applyfor-main {
    padding: 255px 0px 0px 0px;
  }
}


@keyframes demo {
  0% {
    opacity: 0;

  }

  100% {
    opacity: 1;
  }
}

.scholarship-form-container-outer .pagination li a {
  border-radius: 6px;
}

.nav-dropdown.dropdown-menu a {
  transition: all 0.3s ease-in-out;
}

.nav-dropdown.dropdown-menu a:hover {
  background-color: #ece2ee !important;
}

.isActiveted {
  background-color: red !important;
}

.dropdown-item .focused {
  background-color: #7f3487 !important;
}


/* Define transition effect for the content */
.bottom-line {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
  opacity: 0;
}

.bottom-line-show {
  max-height: 500px;
  opacity: 1;
}





/*scholarship-logs-confiner*/

.scholarship-logs-confiner {
  margin-top: 110px;
  padding: 70px 50px;
}

.hr-line {
  height: 1px;
  background-color: #722F79;
  width: 30px;
}

.set-back-color {
  background-color: #722F79;
  border-radius: 7px;
  padding: 4px 4px;
  width: fit-content;
}

.text-back-color {
  background-color: #e6dae7;
  padding: 12px 20px;
  font-size: 18px;
  font-weight: 700;
  border-radius: 8px;
}


/*informed-on-track*/
.informed-on-track {
  background-color: #f4f4f4;
  margin: 0px -24px;
  padding: 35px;
}

.stay-track-text {
  font-weight: 800;
  font-size: 36px;
  color: #000;
  font-family: 'Mulish';
}

.informed-on-track-desc {
  /* max-width: 484px; */
  font-size: 18px;
  color: #5D596C;
  font-weight: 600;

}

.add-margin-bottom {
  margin-bottom: 16px;
}

.informed-on-track-inner {
  max-width: 1440px;
  width: 100%;
  margin: auto;
  gap: 30px;
}

.scholarship-logo {
  filter: grayscale(1);
  transition: all 0.5s ease-in-out;
}

.scholarship-logo:hover {
  filter: grayscale(0);
}

.scholarship-logo-container {
  margin-top: 62px;
}

.logo-slider-container {
  .slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.slide-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Set your desired height */
}

.scholarship-logo {
  height: 100%;
  max-width: 100%;
  object-fit: contain; /* Ensure the image fits within the container without distortion */
}

@media(max-width:1130px) {
  .app-status-img {
    height: auto !important;
    width: 100% !important;
  }
}

@media(max-width:1023px) {
  .stay-track-text {
    font-size: 28px;
  }

  .informed-on-track-desc {
    font-size: 16px;

  }
}

@media(max-width:767px) {
  .informed-on-track-inner {
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;
  }
}
@media(max-width:320px) {
  .informed-on-track-inner {
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;
  }

  /* slider */


}

