.navbar {
  padding: 12px 36px !important;
  max-width: 1920px;
  /* padding:4px !important;   */
  height: 62px;
  margin-bottom: 26px;
  /* position: absolute !important; */
  z-index: 2;
  width: 100%;
  background-color: #fff;
  border-radius: 6px;
  left: 0;
  transition: all 0.5s ease !important;
}

.navbar-expand-md {
  justify-content: space-between !important;
}

.navbar-brand {
  margin-right: 0rem !important;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.closebtn {
  align-items: flex-end;
  justify-content: end;
  padding: 0rem 0.5rem;
  font-size: 1.75rem;
}

.navbar-left {
  flex-grow: 1 !important;
}
/* --------------------------------- */
/* .hidden {
  display: none;
}

.header-visible {
  top: 0;
  }

  
  .navbar{
		transition: top 0.3s;
	  }
  .navbar {
  transition: transform 0.3s ease-in-out !important; 
  
  transform: translateY(0); 
  }
  
  .hidden {
  transform: translateY(-100%); 
  }
  .header-hidden {
  top: -104px;
  } */

/* --------------------------------------- */

.navlink a.active {
  color: var(--theme-color) !important;
  border-bottom: 2px solid var(--theme-color) !important;
 
}
.nav-div {
  padding: 0.5rem 1rem !important;
  cursor: pointer;
}

.active-nav-div {
  color: var(--theme-color) !important;
  border-bottom: 2px solid var(--theme-color) !important;
  padding: 0.5rem 1rem !important;
}

.navlink a {
  font-family: var(--theme-font-family-hading);
  text-decoration: none;
  color: var(--theme-color2) !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  
}

.userinfo {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.bellicon img { width: 38px;
  height: 38px;
  width: 26px;
  height: 26px;
}

.bellicon .notificationCount {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 13.23px;
  top: -3.23px;
  font-weight: 700;
  font-size: 13px;
  line-height: 14px;
  background: #ea5455;
  border-radius: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #ffffff;
}

.username h6 {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: right;
  color: #6e6b7b;
  margin-bottom: 0rem !important;
}

.username span {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: #b9b9c3;
}

.user {
  background: #856ef7;
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #ffffff;
  width: 38px;
  height: 38px;
  border-radius: 50%;
}

.useractive {
  border: 2px solid #ffffff;
  position: absolute;
  left: 65.06%;
  right: 5.89%;
  top: 64.63%;
  bottom: 3.32%;
  background: #28c76f;
  width: 11px;
  height: 11px;
  border-radius: 50%;
}

.signup-text {
  padding-left: 1rem;
}

.signup-text span,
.signup-text a {
  color: var(--theme-color);
  font-size: 15px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.43px;
  text-decoration: none !important;
}
.dropdown-padding-none{
  padding: 0px !important;
}
.dropdown-custom-color:active{
 border: none !important;
}
.dropdown-custom-color:hover{
  border: 1px solid transparent !important;
 }
 .dropdown-custom-color:focus-visible{
  border: none !important;
 }
.dropdown-item.active, .dropdown-item:active {
  
  text-decoration: none;
  background-color: #d4b6d4 !important;
}
.dropdown-top-margin.show{
  /* margin-top: 14px; */
}
.show.collapse.navbar-left{
 transition: all 0.5s ease-in;
} 
a.nav-link{
  padding: 8px 16px !important;
 
}
a.nav-link.active{

}



.dropdown-item.focused {
  border-bottom: 2px solid var(--theme-color) !important; /* Example border color */
  color: var(--theme-color) !important;
}

.custom-disable-link{
  pointer-events: none;
  opacity: 0.6;
  cursor:no-drop !important ;
}
.cursor-pointer{
  cursor: pointer;
}
.cursor-not-allowed { cursor: not-allowed; }


/* Ensure the dropdown stays open on click */
.dropdown-menu.show {
  display: block;
}

.mobile-view-show{
  display: none;
}

img.main-header-left-arrow{
  height: 12px !important;
  width: 12px !important;
}

.hover-roatet a img{
  margin-left: 6px !important;
}
.hover-roatet:hover img.main-header-left-arrow{
  transform: rotate(90deg) !important;
}
.text-color-add{
  color:#5d596c !important
}

/* 
.dropdown-menu {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease;
}

.dropdown.show .dropdown-menu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
} */





@media(min-width:768px){
  .dropdown-menu {
    display: none;
  }
  
  /* Show the dropdown menu when the parent is hovered */
  .dropdown.show:hover .dropdown-menu {
    display: block;
    /* margin-top: 14px; */
  }
  
  /* Ensure the dropdown stays open on click */
  .dropdown-menu.show {
    display: block;
  }
}

/* .dropdown-menu Link:active{
  background-color: #349eff !important;
} */
/* .dropdown-item:active{
  background-color: #28c76f !important;
} */

/* ----------------------------------- */

/* ---------------------------------------------- */

.dropdown-top-margin .dropdown-item:hover{
  background-color: #7f348725;
}
@media (max-width: 1299px) {
  .navbar {
    padding: 12px 20px !important;
   
  }
}

@media (max-width: 991px) {
  .navbar {
    margin: 0px;
  }
  .username {
    display: none !important;
  }

}

@media (max-width:991px) {
  .margin-bottom-20px{
    margin-bottom: 20px !important;
  }
  .mobile-view-show{
    display: block;
  }
  .mobile-view-display-none{
    display: none;
  }
  .padding-add{
    padding: 18px 15px 18px 30px !important;

  }

  .no-padding{
    padding: 0px !important;
  }
}

@media (max-width:850px) {
  .navbar-brand img{
    width: 140px;
  }
  .userinfo {
    gap: 0.2rem;
}
a.nav-link{
  padding: 8px 8px !important;
  font-size: 15px !important;
 
}


.navlink .nav-div{
  padding: 8px 8px !important;
  font-size: 15px !important;
  color: #5d596c;
  
}
.dropdown-custom-color{
  font-size: 15px !important;

}
.dropdown-padding-none{
  
}
}


@media (max-width:1100px) {
  .username {
   display: none !important;
 }
}

/* @media (max-width: 991px) {
 .navbar-expand-md .navbar-toggler {
     display: block !important;
    
 }
 .navbar-brand {
   order: 1;
}
} */
@media (max-width: 990px) {
  .navbar-expand-md .navbar-toggler {
      display: block !important;
     
  }
  .navbar-brand {
    order: 1;
}
}


@media (min-width: 991px) {
  .navbar-expand-md .navbar-left {
    display: flex !important;
    justify-content: space-evenly !important;
    flex-basis: auto;
  }


}
@media (max-width: 991px) {
  .navbar-expand-md .navbar-nav {
      flex-direction: column !important;
  }
}


@media (max-width: 991px) {
  .navbar{
    flex-wrap: nowrap !important;
   
  }
  a.nav-link{
    padding: 18px 30px !important;
    font-size: 16px !important;
  }
  .navlink .nav-div{
    padding: 18px 30px !important;
    font-size: 16px !important;
    color: #5d596c;
    font-family: 'mulish';
  }
  .dropdown-custom-color{
    font-size: 16px !important;
    padding: 18px 30px !important;
  }
  .dropdown-top-margin{
    margin: 0px auto !important;
    width: fit-content;
  }
  .navbar-left.collapse.show {
    height: 100%;
    max-width: 250px;
    width: 100%;
    position: fixed;
    z-index: 1;
    /* top: 79px; */
    top: 0px;
    left: 0;
    /* overflow-x: hidden; */
    transition: all 0.5s ease;
    background-color: var(--background-color);
  }
  
  .navbar-nav {
    background-color: var(--background-color);
  }

  .navbar-brand {
    order: 1;
  }

  .navbar-toggler {
    order: 0;
  }

  .userinfo {
    order: 2;
  }
  .dropdown-top-margin.show{
    margin-top: 0px;
  }
}

@media (max-width: 575px) {
  .navbar {
    padding: 12px 12px !important;
  }
  .useractive {
    left: 72.06%;
    top: 68.63%;
}

  /* .userinfo {
        display: none;
    }  */

  /* .navbar-brand {
        order: 0;
    } */

  .userinfo .dropdown .dropdown-toggle.btn-div {
    padding: 0px;
  }
  .userinfo {
    gap: 5px;
  }
  .userinfo .secondary-btn {
    padding: 0px 10px;
  }
  .userinfo .signup-text {
    display: none;
  }
}

@media (max-width: 375px) {
  /* mobile */

  /* .nav-logo{

  } */
  /* .navbar-toggler-icon {
    width: 1em !important;
  } */
  .toggle-btn-wrap .navbar-toggler-icon {
    width: 20px ;
  }


  .navbar {
    flex-wrap: nowrap !important;
  }

  .navbar-brand img {
    width: 115px !important;
  }
  .user{
    width: 30px;
    height: 30px;
  }
}


@keyframes gradbar {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}