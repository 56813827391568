.profilemodal-container.modal-dialog {
    width: 100% !important;
    padding: 24px 32px;
    max-width: 712px;
    margin: auto !important;
}

.personalinfo-container {
    padding: 24px 32px;
    border-radius: 4px;
    gap: 24px;
}

.profile-image-content {
    gap: 20px;
    padding: 0px 20px;
}

.profile-image {
    background: url(../../assets/Background.png);
    width: 150px;
    height: 150px;
    background-repeat: no-repeat;
    background-size: 150px;
    border-radius: 100px;
}

.profile-image img {
    width: 150px;
    height: 150px;
    border-radius: 100px;
}

.profile-info-tab {
    gap: 24px;
    width: 100%;
}

.profile-image-content span {
    color: #4B465C;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
}

.detail-tab-container {
    gap: 24px;
    display: flex;
}

.personal-detail-tab {
    gap: 16px;
    cursor: pointer;
}

.personal-detail-tab.active .details-image {
    background: var(--theme-color);
}

.personal-detail-tab.active .details-image img {
    filter: brightness(3);
}

.details-image {
    padding: 8px;
    box-shadow: 0px 2px 4px 0px rgba(165, 163, 174, 0.30);
    border-radius: 6px;
    width: 40px;
    height: 40px;
    background-color: rgba(75, 70, 92, 0.08);
}

.details-image img {
    width: 24px;
    height: 24px;
}

.personal-detail-tab span {
    color: #4B465C;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
}

.profile-divider {
    height: 1px;
    background: var(--theme-color);
}

.details-title {
    color: #4B465C;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
}

@media (max-width:991px) {
    .profilemodal-container.modal-dialog .personalinfo-container {
        margin-top: 0px !important;
    }
}

@media (max-width:767px) {
    .profile-info-tab {
        gap: 24px;
    }

    .personalinfo-container {
        padding: 24px;
    }
}

@media (max-width:425px) {
    .profile-info-tab {
        flex-direction: column !important;
    }

    .detail-button-container {
        flex-direction: column;
        gap: 16px;
        justify-content: flex-end !important;
        align-items: end;
    }
}