.previous-btn {
    background-color: #fff;
    width: fit-content;
    border: 2px solid var(--theme-color);
    border-radius: 6px;
    box-shadow: 0px 2px 4px rgba(165, 163, 174, 0.3);
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: 0.43px;
    color: var(--theme-color);
    padding: 0rem 1.5rem;
    height: 48px;
    gap: 8px;
}
.apply-white-color{
    mix-blend-mode:exclusion;
}

.previous-btn:disabled {
    opacity: 0.5;
}

@media (max-width:425px) {
    .previous-btn {
        font-size: 13px;    
        padding: 0rem 1rem;
        height: 40px;
    }
}