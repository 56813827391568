.set-banner{
    transform: translate(0px, -105px);
    margin: 0px -24px;
    
}

.main-title{
    color: #263238;
    font-size: 28px;
    font-weight: 700;
}

.image-card-start{
    max-width: 1450px;
    margin: 0 auto;
    margin-bottom: 50px;
}
.custom-add-gap{
    row-gap: 35px;
    column-gap: 30px;
}

.main-title{
    margin-bottom: 26px;
}

.spacing-add{
    gap: 80px;
}

.add-margin-right{
    margin-right: 20px;
}