.applynow-btn {
    background-color: var(--theme-color);
    width: fit-content;
    border: none;
    border-radius: 6px;
    box-shadow: 0px 2px 4px rgba(165, 163, 174, 0.3);
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.43px;
    color: #FFFFFF;
    /* width: 178px; */
    padding: 0rem 1.5rem;
    height: 48px;
    font-family: var(--theme-font-family-mulish);
}
.applynow-btn[disabled=disabled],.applynow-btn:disabled {
    background-color: var(--theme-color2);
    width: fit-content;
    border-radius: 6px;
    box-shadow: 0px 2px 4px rgba(165, 163, 174, 0.3);
    font-weight: 600;
    font-size: 16px !important;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.43px;
    color: #FFFFFF;
    /* width: 178px; */
    padding: 0rem 1.5rem;
    height: 48px;
    cursor: not-allowed;
}

@media (max-width:425px) {
    .applynow-btn {
        font-size: 13px;
        padding: 0rem 1rem;
        height: 40px;
    }
}
@media (max-width:1000px) {
    .applynow-btn {
       width: 105px ;
       font-size: 15px !important;
    }
}

.btn-loader {
  width: 10px;
  height: 10px;
  border: 3px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 