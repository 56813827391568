.status-title {
    color: var(--theme-color);
    font-size: 24px;
    font-weight: 700;
    line-height: 60px;
}

.status-name {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    color: var(--theme-color2);
}

.approvedtext,
.unapprovedtext {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.056px;
    color: var(--theme-color3);
    text-align: center;
    display: flex;
    margin-top: 8px;
}

.unapprovedtext {
    opacity: 0.6;
}

.status-image {
    height: 30px;
    max-height: 30px;
    display: block;
}

.status-line-mobile {
    display: none;
}

.status-date {
    color: #263238;
    opacity: 0.6;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.04px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    width: max-content;
    margin: auto;
}
.scholarship-accordian{
    margin-bottom: 20px;
}

.status-main-container {
    box-shadow: 0px 4px 18px 0px rgba(75, 70, 92, 0.10);
}

@media (max-width:1245px)and (min-width:992px) {
    .status-line {
        width: 100px;
    }
}

@media (max-width:991px) {
    .status-title {
        line-height: 20px;
        margin: 32px 0px;
    }
}

@media (max-width:630px) {
    .status-line {
        width: 100px;
        display: none;
    }

    .status-line-mobile {
        display: flex;
    }
}

@media (max-width:576px) {
    .status-title {
        font-size: 16px;
    }

    .status-date {
        font-size: 5px;
        line-height: 10px;
        margin-bottom: 4px;
        letter-spacing: 0.02px;
    }

    .approvedtext,
    .unapprovedtext {
        font-size: 7px;
        line-height: 10px;
        margin-top: 4px;
        letter-spacing: 0.028px;
    }

    .status-image {
        height: 15px;
        min-height: 15px;
    }

    .status-container {
        gap: 12px !important;
    }

    .status-name {
        font-size: 12px;
    }
}

@media (max-width:425px) {

    .approvedtext,
    .unapprovedtext {
        justify-content: center;
    }

    .status-main-container {
        padding: 1rem !important;
    }
}

@media (max-width:375px) {
    .status-line-mobile {
        width: 50px;
    }
}